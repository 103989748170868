import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { envSpecificVariables } from './AppConfig';
import { UNAUTHORIZED_MESSAGE } from './constants';

const axiosInstance = axios.create({
  baseURL: envSpecificVariables.apiUrl,
  headers: {
    Accept: 'Application/json',
    'Content-Type': 'application/json'
  },
});

export default class Axios {
  static POST = (url: string, data: any, customConfig?: any): Promise<any> => {
    const config = this.getConfig(customConfig);
    return axiosInstance
      .post(url, data, config)
      .then((response) => Axios.checkAuthorization(response, url, data, customConfig))
      .catch(Axios.errorHandlerBase);
  };

  private static getConfig(customConfig: any) {
    return customConfig
      ? {
        ...axiosInstance.defaults,
        headers: {
          ...axiosInstance.defaults.headers,
          ...customConfig.headers,
        },
      }
      : axiosInstance.defaults;
  }

  private static checkAuthorization = async (res: any, url: string, data: any, customConfig: any): Promise<any> => {
    if (
      res.data.errors
      && res.data.errors.some(
        (error: any) => error.message && error.message === UNAUTHORIZED_MESSAGE
      )
    ) {
      await Axios.refreshToken();
      return axiosInstance.post(url, data, Axios.getConfig(customConfig))
        .then(Axios.successHandlerBase)
        .catch(Axios.errorHandlerBase);
    }
    return Axios.successHandlerBase(res);
  };

  private static successHandlerBase = (res: any) => {
    if (res.status === 200) {
      return Promise.resolve(res);
    }
    return Promise.reject(res);
  };

  private static errorHandlerBase = async (error: any) => {
    console.error(error);
    return Promise.reject(error);
  };

  private static refreshToken = async () => {
    const accessToken = await getAuth().currentUser?.getIdToken();
    await Axios.updateToken(accessToken);
    return accessToken;
  };

  public static updateToken = async (accessToken: any) => {
    if (axiosInstance && accessToken) {
      axiosInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;
    }
  };
}
