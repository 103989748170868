import React, { useState, useEffect, Suspense } from 'react';
import './scss/style.scss';
import {
  HashRouter as Router, Route, Routes, Navigate
} from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useDispatch } from 'react-redux';
import Axios from './AxiosWrapper';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

function App(): JSX.Element {
  const [tokenValid, setTokenValid] = useState(false);
  const [loadingAfterLogged, setLoading] = useState(true);
  const dispatch = useDispatch();
  const auth = getAuth();
  useEffect(() => {
    const checkTokenExpiry = () => {
      const idToken = localStorage.getItem('token');
      auth.onAuthStateChanged(async (user3) => {
        if (user3) {
          auth.onIdTokenChanged(async (user2) => {
            const tokenNew = await user2?.getIdToken();
            if (tokenNew && tokenNew !== idToken) {
              await Axios.updateToken(tokenNew);
              setTokenValid(true);
            } else {
              setTokenValid(true);
            }
            setLoading(false);
          });
        } else {
          setTokenValid(false);
          setLoading(false);
        }
      });
    };
    checkTokenExpiry();
  }, [auth, dispatch]);
  if (loadingAfterLogged) {
    return (
      <div className="pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse" />
      </div>
    );
  }
  return (
    <Router>
      <Suspense fallback={loading}>
        <Routes>
          {!tokenValid && <Route path="/" element={<Navigate to="login" replace />} />}
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/500" element={<Page500 />} />
          <Route path="*" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
