/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable default-param-last */
import { applyMiddleware, createStore, Reducer } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface initialState {
  sidebarShow: boolean;
  authToken: string;
  user: any,
  logout: boolean,
  isLoggedIn: boolean,
  isPushNotificationSending: { [key: string]: string },
}

const initialAppState: initialState = {
  sidebarShow: true,
  authToken: '',
  user: null,
  logout: false,
  isLoggedIn: false,
  isPushNotificationSending: {},
};

const changeState: Reducer<initialState, { type: string; [key: string]: any }> = (
  state = initialAppState,
  { type, ...rest }
) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest };
    case 'UPDATE_AUTH_TOKEN':
      return { ...state, authToken: rest.authToken };
    case 'SET_USER':
      return { ...state, user: rest.user };
    case 'LOGOUT':
      return { ...state, logout: rest.logout };
    case 'LOGIN':
      return { ...state, isLoggedIn: rest.isLoggedIn };
    case 'IS_PUSH_NOTIFICATION_SENDING':
      return { ...state, isPushNotificationSending: { ...state.isPushNotificationSending, ...rest } };
    default:
      return state;
  }
};

const persistConfig = {
  key: 'root',
  storage,
};

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, changeState);

// Create the Redux store
const store = createStore(persistedReducer, applyMiddleware(/* Add any middleware here */));
const persistor = persistStore(store);

export { store, persistor };
