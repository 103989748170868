const firebaseConfig = {
  apiKey: 'AIzaSyArkPpwn9_SEY-1qCZndcD5FyxKLI_wm7Y',
  authDomain: 'alifcare-dev.firebaseapp.com',
  projectId: 'alifcare-dev',
  storageBucket: 'alifcare-dev.appspot.com',
  messagingSenderId: '630174635745',
  appId: '1:630174635745:web:8ff66b676b4bae11b9c9a0',
  measurementId: 'G-W2W30EKG0G'
};

export const envSpecificVariables = {
  apiUrl: 'https://test.alif.care/graphql',
  apiAccessKey: 'hamf5bc4dc562a902f77844c582b0af869'
};

export default firebaseConfig;
