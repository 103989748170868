import { User } from './views/pages/patientSummary/PatientList';

/* eslint-disable no-shadow */
export enum PushNotificationStatus {
    SENDING = 'sending',
    SENT = 'sent',
    FAILED = 'failed'
}
export const UNAUTHORIZED_MESSAGE = 'Unauthorized';

export enum NutritionScheduleTime {
  BREAKFAST = 'Breakfast',
  MORNING_SNACK = 'Morning_Snack',
  LUNCH = 'Lunch',
  EVENING_SNACK = 'Evening_Snack',
  DINNER = 'Dinner',
}
export interface IVitalLimit {
    condition: string;
    upperLimit: number;
    lowerLimit: number;
  }
export interface IVitalSchedule {
    vitalLimit: IVitalLimit;
    uuid?: string;
    instructions: string;
    activityTime: string;
    weekDays: string[];
    time: string;
  }
export interface ILabHba1cData {
    currentHba1c?: number;
    goalHba1c?: number;
  }

export interface IWeightSchedule {
    baseWeight?: number;
    targetWeight: number;
    weightChangeType: string;
    uuid?: string;
    instructions: string;
    activityTime: string;
    weekDays: string[];
    time: string;
  }

export interface IExercise {
    type: string;
  }

export interface IExerciseSchedule {
    uuid?: string;
    exercise: IExercise;
    instructions: string;
    activityTime: string;
    weekDays: string[];
    time: string;
  }

export interface INutritionSchedule {
    allowedCalories: number;
    allowedCarbs: number;
    type: NutritionScheduleTime;
    uuid?: string;
    instructions: string;
    activityTime: string;
    weekDays: string[];
    time: string;
  }

export interface INutritionPlan {
    dailyCalories: number;
    dailyCarbs: number;
  }
export interface IMedicine {
    uuid?: string;
    name: string;
    unit?: string;
    strength: string;
    createdByPatient?: boolean;
  }
export interface IMedicineSchedule {
    dosage?: number;
    medicine: IMedicine;
    uuid?: string;
    instructions: string;
    activityTime: string;
    weekDays: string[];
    time: string;
    formattedEndDate?: string | undefined;
  }

export interface IOtherPatientInfo {
  initialBmi?: number
}
export interface IUpdatePatientPlanArgs {
    updateVitalSchedule?: IVitalSchedule[];
    updateVitalLimit?: IVitalLimit[];
    updateLabSchedule?: ILabHba1cData;
    updateWeightSchedule?: IWeightSchedule[];
    updateExerciseSchedule?: IExerciseSchedule[];
    updateNutritionSchedule?: INutritionSchedule[];
    updateNutritionPlan?: INutritionPlan;
    updateMedicineSchedule?: IMedicineSchedule[];
    otherPatientInfo?: IOtherPatientInfo;
  }
export enum PatientPlanCategories {
    VITAL = 'Vital',
    MEDICINE = 'Medicine',
    LAB = 'Lab',
    EXERCISE = 'Exercise',
    NUTRITION = 'Nutrition',
    WEIGHT = 'Weight',
    ADMIN_NOTES = 'Admin_notes',
  }

export enum BglCondition {
    FASTING = 'FASTING',
    POSTMEAL = 'POSTMEAL',
  }

export enum WeightChangeType {
    LOSE = 'Lose',
    GAIN = 'Gain',
    MAINTAIN = 'Maintain',
  }
export enum ExerciseType {
    LIGHT_ACTIVITY = 'LIGHT_ACTIVITY',
    MODERATE_ACTIVITY = 'MODERATE_ACTIVITY',
  }

export interface IUserPatientInformation {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  city?: string;
  country?: string;
  isPatient?: boolean;
  isAdmin?: boolean;
  isActive?: boolean;
  gender?: string;
  dateOfBirth?: Date;
  height?: string;
  condition?: string;
  allergies?: string[];
  tags?: string[];
  primaryGoals: string[];
  stressLevel: string;
  hoursOfSleep: number;
  isWorkingProfessional?: boolean;
  firstAssessmentBooked?: boolean;
  trialEndsOn?: Date;
  subscriptionPlanType: string;
 }

export enum PatientCondition {
  DIABETIC = 'Diabetic',
  PRE_DIABETIC = 'Pre-Diabetic',
  NONE = 'None of the above',
}

export const assessmentTypeCategories = [
  { label: 'Nutritional Assessment', value: 'Nutritional Assessment' },
  { label: 'Clinical Assessment', value: 'Clinical Assessment' },
];

export const assessmentStatusCateogries = [
  { label: 'Pending', value: 'PENDING' },
  { label: 'Booked', value: 'BOOKED' },
  { label: 'Completed', value: 'COMPLETED' },
];

export const amPmOption = [
  {
    label: 'AM', value: 'AM',
  },
  {
    label: 'PM', value: 'PM'
  }
];

export interface Patient {
  id: number,
  uuid: string,
  user: User;
  allergies: string[],
  tags: string[],
  email: string;
}

export interface PatientAssessment {
  uuid?: string;
  type: string;
  scheduledAt: Date | null;
  completedDate: Date | null;
  assessmentStatus: string;
  time?: string;
  patient?: Patient
}

export const subscriptionPlanTypes = [
  { label: 'Free', value: 'Free' },
  { label: 'Trial', value: 'Trial' },
  { label: 'Premium', value: 'Premium' }];

export enum PaymentStatus {
  VALID = 'Valid',
  EXPIRED = 'Expired',
  NO_PAYMENT_MADE = 'No Payment Made',
}

export interface PatientFile {
  uuid: string;
  createdAt: string;
  category: string;
  type: string;
  s3Path: string;
  notes: string;
  name: string;
}

export enum FileCategory {
  LAB_REPORT = 'lab',
  PRESCRIPTION = 'prescription'
}
export enum LabType {
  HBA1C = 'HbA1C',
  URINARY_ACR = 'Urinary ACR',
  RFTS = 'RFTs',
  LFTS = 'LFTs',
  LIPID_PROFILE = 'Lipid Profile',
  CBC = 'CBC',
  COMPLETE_URINALYSIS = 'Complete Urinalysis',
  FUNDOSCOPY = 'Fundoscopy',
  ARTERIAL_DOPPLER_ULTRASOUND_FOR_LOWER_LIMBS = 'Arterial Doppler Ultrasound For Lower Limbs',
  ULTRASOUND_ABDOMEN = 'Ultrasound Abdomen',
  CT_CORONARY_ANGIOGRAPHY = 'CT Coronary Angiography',
  SERUM_VIT_B12 = 'Serum Vit B12',
  SERUM_CALCIUM = 'Serum Calcium',
  VITAMIN_D3_LEVELS = 'Vitamin D3 Levels',
  OTHER = 'Other'
}

export interface PatientPaymentLinkDto {
  userUuid: string,
  amount: number,
  orderId: string,
  validityDurationInMonths: number
}

export const paymentPlanType = [
  { label: 'Select a valid payment plan', value: '' },
  { label: 'Monthly Subscription Without Discount', value: 'Monthly' },
  { label: 'Monthly Subscription With Discount', value: 'Monthly_discounted' },
  { label: 'Monthly Subscription With 50% Discount', value: 'Monthly_50_off' },
  { label: 'Discounted Quarterly Payment', value: 'Quarterly_discounted' },
];

export const paymentPlanToDurationMapping: {
  [key: string]: number;
} = {
  Monthly: 1,
  Monthly_discounted: 1,
  Monthly_50_off: 2,
  Quarterly_discounted: 3,
};
